import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import Metadata from "../components/metadata"

import Hero from "../components/hero"

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    padding: 25px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
  }

  tr:nth-child(even) {
    background-color: var(--color-silver);
  }
  tr:hover {
    background-color: var(--color-whitesmoke);
  }
`

const ProductWrapper = styled.div``

const Products = ({ data }) => {
  const intl = useIntl()
  const { heroImage } = data

  return (
    <Layout>
      <Metadata title={intl.formatMessage({ id: "products.title" })} />
      <Hero image={heroImage} />
      <h1 className="d-flex m-2 p-3 justify-content-center align-items-center">
        {intl.formatMessage({ id: "products.title" })}
      </h1>
      <hr />
      <ProductWrapper>
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-9 mx-auto text-center">
              <h3>{intl.formatMessage({ id: "products.subtitle" })}</h3>
              <p className="lead mb-0">
                {intl.formatMessage({ id: "products.description" })}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-9 mx-auto">
              <Table className="table table-hover">
                <tbody>
                  <tr>
                    <td>
                      {intl.formatMessage({
                        id: "products.product_table.doors",
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: "products.product_table.spalni",
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: "products.product_table.kuhni",
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {intl.formatMessage({
                        id: "products.product_table.sekcii",
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: "products.product_table.shkavcheta",
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: "products.product_table.byura",
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {intl.formatMessage({
                        id: "products.product_table.stulbishta",
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: "products.product_table.lamperii",
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: "products.product_table.masi",
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {intl.formatMessage({
                        id: "products.product_table.mebeli",
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: "products.product_table.za_detski_stai",
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: "products.product_table.ofis_mebeli",
                      })}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </ProductWrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ProductsPageQuery {
    heroImage: file(relativePath: { eq: "products/hero2.jpg" }) {
      childImageSharp {
        fluid {
          src
          srcSet
          aspectRatio
          sizes
        }
      }
    }
  }
`

export default Products
